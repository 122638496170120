import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Layout from "./Pages/Layout";
import HomePage from "./Pages/HomePage";
import WhiteListPage from './Pages/WhiteListPage';
import { Header } from './component/Header/Header';
import { Footer } from './component/Footer/Footer';
import { useCookies } from "react-cookie";
import { useEffect } from 'react';

function App() {
  const [cookies, setCookie, removeCookie] = useCookies([]);

  useEffect(() => {
    if (cookies.PieVerse_walletAddress) {
      window.sessionStorage.setItem("PieVerse_walletAddress", cookies.PieVerse_walletAddress)
    }
  }, [cookies.PieVerse_walletAddress])

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          {/* <Route path="/" element={<Layout />}> */}
          {/* <Route index element={<HomePage />} /> */}
          {/* <Route path="*" element={<NoPage />} /> */}
          <Route path="/" element={<HomePage />} ></Route>
          <Route path="/addwhitelist" element={<WhiteListPage />}  ></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
