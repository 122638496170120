import React from 'react';
import Container from 'react-bootstrap/Container';
import { Egyals } from '../assist/SVG/Egyals';
import { Side } from '../assist/SVG/Side';


export const Footer = () => {
  return (
    <div className='footer py-3 pb-md-5'>
      <Container className='custom_footer_container'>
        <ul className='list-group custom_footer_ul list-group-horizontal justify-content-end'>
          <li className='list-group-item bg-transparent'><Side color="#fff" /></li>
          <li className='list-group-item bg-transparent'><Egyals color="#fff" /></li>
        </ul>
      </Container>
    </div>
  )
}
