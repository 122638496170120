/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import detectEthereumProvider from "@metamask/detect-provider";
import { Networks } from "./networks";
import { useCookies } from "react-cookie";
import event from "./../hooks/Event"


async function initWeb3(provider) {
  const web3 = new Web3(provider);

  await web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });

  return web3;
}

const AccountModal = (props) => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies([]);

  let web3Modal = null;
  let web3 = null;
  let provider = null;



  const init = async () => {
    const providerOptions = {
      metamask: {
        package: detectEthereumProvider,
        options: {
          rpc: {
            4: process.env.REACT_APP_RPC_URL,
          },
        },
      },
    };

    web3Modal = new Web3Modal({
      network: process.env.REACT_APP_NETWORK,
      cacheProvider: false,
      providerOptions: providerOptions,
    });

    provider = await detectEthereumProvider();
    web3 = await initWeb3(provider);
    if (web3 && provider) {
      if (web3.eth) {
        provider.on("accountsChanged", async function (accounts) {
          // let acc = await web3?.eth?.getAccounts();
          if (accounts.length > 0) {
            setCookie("PieVerse_walletAddress", accounts[0], { path: "/" });
            setCurrentAccount(accounts[0])
            event.emit("checkLogin")
            window.location.reload()
          }
          else {
            removeCookie("PieVerse_walletAddress", { path: "/" });
            setCurrentAccount(null)
          }
        });
        // provider.on("chainChanged", async function (chainID) {
        //   console.log("Chain ID", chainID)
        //   if(chainID !== "0x4"){
        //     console.log("Function call");
        //     handleNetworkSwitch(process.env.REACT_APP_NETWORK);
        //   }
        // });

      }
    }
  };


  init();



  useEffect(() => {
    window.addEventListener("load", async () => {
      console.log("metamask detect", window.ethereum);
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        try {
          window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((acc) => {
              // console.log("acc", acc)
              // if (acc.length == 0)
              //   window.location.reload()
            })
            .catch((error) => {
              if (error.code === 4001) {
                console.log("Please connect to MetaMask.");
                event.emit("disable-mint");
              } else {
                console.error(error);
                event.emit("disable-mint");
              }
            });
          const chainId = await window.web3.eth.getChainId();
          sessionStorage.setItem("chain_id", chainId.toString());

        } catch (error) {
          console.log("user denied wallet")
          return
        }
      }
      else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      }
      else {
        console.log(
          "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
      }
    });
  }, []);

  useEffect(() => {
    async function update() {
      if (cookies.PieVerse_walletAddress !== null) {
        setCurrentAccount(cookies.PieVerse_walletAddress);

        if (provider) {
          web3 = await initWeb3(provider);
          let bal = await web3.eth.getBalance(cookies.PieVerse_walletAddress);

          setCookie("balance", bal);
        }
      }
    }

    update();
  }, [cookies.PieVerse_walletAddress, web3, provider]);


  const onConnect = async () => {
    //Detect Provider
    try {
      console.log("heree")
      provider = await web3Modal.connect();
      if (provider.open) {
        await provider.open();
        web3 = initWeb3(provider);
      }
      if (!provider) {
        console.log("no provider found");
      } else {
        web3 = new Web3(provider);
        await ConnectWallet();
      }
      const chainId = await web3.eth.getChainId();
      sessionStorage.setItem("chain_id", chainId.toString());
      event.emit("checkLogin")
    } catch (error) {
      console.log(error);
    }
    window.location.reload()
  };

  event.removeAllListeners("connectWallet");
  event.on("connectWallet", onConnect)


  const ConnectWallet = async () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    try {
      const chainId = await web3.eth.getChainId();
      sessionStorage.setItem("chain_id", chainId.toString());

      if (web3 && web3.eth) {
        const accounts = await web3.eth.getAccounts();

        setCookie("PieVerse_walletAddress", accounts[0], { path: "/" });
        sessionStorage.setItem("PieVerse_IsLoggedIn", true)
        let bal = await web3.eth.getBalance(accounts[0]);
        setCookie("balance", bal, { path: "/" });
        setCurrentAccount(accounts[0]);

      }
    } catch (error) {
      if (error.message) {
        console.log("error", error.message);
      }
    }
  };

  //  disconnect wallet

  const onDisconnect = useCallback(async () => {
    if (!web3) {
      removeCookie("PieVerse_walletAddress", { path: "/" });
      removeCookie("balance", { path: "/" });
      removeCookie("Authorization", { path: "/" });
    }
    sessionStorage.setItem("PieVerse_IsLoggedIn", false)
    removeCookie("balance", { path: "/" });
    removeCookie("PieVerse_walletAddress", { path: "/" });
    removeCookie("Authorization", { path: "/" });
    sessionStorage.removeItem("PieVerse_walletAddress")
    setCurrentAccount(null);
    await web3Modal.clearCachedProvider();
    web3Modal = null;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.disconnect();
    }
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.reload(true);
  }, []);

  useEffect(() => {
    if (provider) {
      provider.on("chainChanged", async (_chainId) => {

        sessionStorage.setItem(
          "chain_id",
          parseInt(_chainId, 16).toString()
        );

      });
    }
  }, [currentAccount, props, provider]);

  useEffect(() => {
    async function updateAccount() {
      if (provider) {
        setCookie("PieVerse_walletAddress", currentAccount, { path: "/" });
        sessionStorage.setItem("PieVerse_IsLoggedIn", true)

        web3 = initWeb3(provider);
        if (web3 && web3.eth) {
          let bal = await web3?.eth?.getBalance(currentAccount);
          setCookie("balance", bal);
        }
      }
    }
    if (currentAccount) {
      updateAccount();
    }
  }, [currentAccount, provider]);

  useEffect(() => {
    if (provider) {
      provider.on("disconnect", (error) => {
        console.log(error);
      });
    }
  }, [provider]);



  const handleNetworkSwitch = async (networkName) => {
    try {
      console.log("network switch request")
      try {
        console.log("1")
        let res = await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Networks[networkName].chainId }],
        });
        console.log("ress", res)
        // await ConnectWallet()
        await onConnect()
      } catch (e) {
        console.log("21", e)
        if (e.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [{ ...Networks[networkName] }],
            });
            return true
          } catch (addError) {
            console.error(addError);
            return false
          }
        }
        return false
      }
    } catch (e) {
      console.log("error in switch", e);
      return false
    }
  };


  event.removeAllListeners("handleNetworkSwitch");
  event.on("handleNetworkSwitch", () => { handleNetworkSwitch(process.env.REACT_APP_NETWORK) })


  useEffect(() => {
    setCurrentAccount(cookies.PieVerse_walletAddress);
  }, [cookies.PieVerse_walletAddress]);

  return (
    <>
      <button
        className="text-dark text-uppercase rounded-pill py-3 px-4 fw-bold"
        style={{ color: props.color }}
        onClick={
          currentAccount ? onDisconnect : onConnect
        }
      >
        {currentAccount
          ? currentAccount.slice(0, 5) + "..." + currentAccount.slice(37, 42)
          : "Connect Wallet"}
      </button>

    </>
  );
};


export default AccountModal;
