import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from '../Button/Button';
// import ProgressBar from 'react-bootstrap/ProgressBar';
import Loader from '../assist/images/loader.png';
import NftImage from '../assist/images/nest-img.png'

import { mint, categoryDetalils, contractMaxQty, qtyMinted, tokensMintedPerCategoryPerAddress, maxQtyPerUser } from '../hooks/connectMetaMask';
import { ToastContainer, toast } from 'react-toastify';
// import BigNumber from 'big-number/big-number';

import sanityClient from "@sanity/client";
// import { ethers } from 'ethers';
import event from "./../hooks/Event"
import { useCookies } from "react-cookie";
import { Networks } from '../AccountModal/networks';

console.log(process.env.REACT_APP_PROJECT_ID, process.env.REACT_APP_DATASET, process.env.REACT_APP_TOKEN)
const userClient = sanityClient({
    "projectId": process.env.REACT_APP_PROJECT_ID,
    "dataset": process.env.REACT_APP_DATASET,
    "token": process.env.REACT_APP_TOKEN,
    useCdn: false
});

export const Home = () => {

    const [selectedAmt, setSelectedAmt] = useState(1);
    const [formFilled, setFormFilled] = useState(false);
    const [totalValue, setTotalValue] = useState("loading");
    const [mintedValue, setMintedValue] = useState("loading");
    const [mintPrice, setMintPrice] = useState("loading");
    const [loader, setLoader] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const [currentWallet, setCurrentWallet] = useState();
    const [maxQtyCategory, setMaxQtyCategory] = useState(0);


    // const remainingValue = 2000
    // const mintPrice = 0.15
    const totalPrice = "" + mintPrice * selectedAmt


    event.removeAllListeners('transactionStart');
    event.on('transactionStart', (tx) => {
        setLoader(true);
        console.log("transaction", tx);
    })
    // evt.on('transactionEnded',(e)=>{
    //     setLoader(false);
    //     console.log("transaction ended", e);
    // })

    const client = sanityClient({
        "projectId": process.env.REACT_APP_PROJECT_ID,
        "dataset": process.env.REACT_APP_DATASET,
        "token": process.env.REACT_APP_TOKEN,
        useCdn: false
    });

    // let account = localStorage.getItem('WalletAccount');

    async function fetchData() {
        let mydata = await client.fetch(`*[_type == 'whitelist'].account`);
        console.log("Data is", mydata);
        const lowerCaseData = mydata.map(element => {
            return element?.toLowerCase();
        });
        return lowerCaseData
    }

    useEffect(() => {
        setCurrentWallet(cookies.PieVerse_walletAddress);
    }, [cookies.PieVerse_walletAddress])

    // useEffect(() => {
    //     console.log("formFilled", formFilled)
    //     let loggedIn = sessionStorage.getItem("PieVerse_IsLoggedIn");
    //     console.log("loggedIn -walletAddress",loggedIn);
    //     if(loggedIn === null)
    //     setFormFilled(false)
    // },[])
    // if(loggedIn === null)
    // setFormFilled(false)


    // evt.on('account-inserted',()=>{
    //     async function fetchUserData() {
    //         let userdata = await userClient.fetch(`*[_type == 'userData'].wallet`);
    //         console.log("User Form Wallet :", userdata);
    //         if(userdata.includes(account)){
    //             setFormFilled(true);
    //         }else{
    //             setFormFilled(false)
    //         }
    //         return userdata
    //     }
    //     fetchUserData();
    // })

    useEffect(() => {
        let loggedIn = sessionStorage.getItem("PieVerse_IsLoggedIn");
        console.log("loggedIn -walletAddress", loggedIn);
        async function fetchUserData() {
            let userdata = await userClient.fetch(`*[_type == 'userData'].wallet`);
            console.log("User Form Wallet :", userdata);
            if (userdata.includes(currentWallet?.toLowerCase()) && loggedIn !== null) {
                setFormFilled(true);
            } else {
                setFormFilled(false)
            }
            return userdata
        }
        async function maxQtyValue() {
            let MaxQty = await contractMaxQty()
            setTotalValue(MaxQty)
        }
        async function mintedQtyValue() {
            let MintedQty = await qtyMinted()
            setMintedValue(MintedQty)
        }
        async function mintPrice() {
            let isPrivate = await categoryDetalils()
            let hexVal = isPrivate[1]
            setMintPrice(hexVal.toString())
            console.log("price in hex : ", hexVal.toString());
            checkMinted();
        }
        async function maxQtyPerUserValue() {
            let maxQtypercat = await maxQtyPerUser();
            console.log("maxQtypercat", maxQtypercat)
            setMaxQtyCategory(maxQtypercat);
        }

        setLoader(false);
        maxQtyPerUserValue();
        fetchUserData();
        maxQtyValue();
        mintedQtyValue();
        mintPrice();
        console.log("maxQtyCategory", maxQtyCategory)
    }, [])

    const checkMinted = async () => {
        let MintedQty = await qtyMinted()
        setMintedValue(MintedQty)
    }

    const notWhiteListed = () => toast.error('User not WhiteListed', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    const handleNetworkSwitch = async (networkName) => {
        try {
            console.log("network switch request")
            try {
                console.log("1")
                let res = await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Networks[networkName].chainId }],
                });
                console.log("ress", res)
                // await ConnectWallet()
                // await onConnect()
                return true
            } catch (e) {
                console.log("21", e)
                if (e.code === 4001) {
                    console.log("user rejected");
                    userRejected()
                    setFormFilled(true);
                    setLoader(false)
                }
                if (e.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [{ ...Networks[networkName] }],
                        });
                        return true
                    } catch (addError) {
                        console.error(addError);
                        return false
                    }
                }
                return false
            }
        } catch (e) {
            console.log("error in switch", e);
            return false
        }
    };

    const txError = async function (errMessage="There has been an error"){
        let lower = errMessage.toLowerCase();
        let errorMessage =  errMessage.charAt(0).toUpperCase() + lower.slice(1);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } 

    const userRejected = () => toast.error('Rejected by User', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

    const mintFunction = async () => {
        console.log("cookies", currentWallet)
        setLoader(true)
        setFormFilled(false);
        if (!currentWallet) {
            event.emit("connectWallet")
            return
        }
        let res = true;
        if (sessionStorage.getItem("chain_id") !== process.env.REACT_APP_CHAIN_ID) {
            res = await handleNetworkSwitch(process.env.REACT_APP_NETWORK)
            console.log("res ponse", res);
        }

        // }
        // console.log("After Switch");
        if(res === true){
            let isPrivate = await categoryDetalils()
        // let hexVal = isPrivate[1]
        // console.log("price in hex : ",hexVal.toString());

        // var hextoEth =new BigNumber(
        //     ethers.utils.parseEther(hexVal.toString()).toString()
        // )
        // var hextoEth =ethers.utils.formatEther(hexVal.toString())
        // const totalPrice = ""+hextoEth.number[0]*selectedAmt
        // console.log("Price to number : ", hextoEth );
        // console.log(isPrivate[0]);

        let maxQty = await maxQtyPerUser();
        console.log("maxQty", parseInt(maxQty));

        let tokenMinted = await tokensMintedPerCategoryPerAddress();
        console.log("tokenMinted", parseInt(tokenMinted));
        let leftQty = maxQty - tokenMinted;
        if (leftQty < selectedAmt) {
            const notWhiteListed = () => toast.error('Quantity limit exceed. Allowed qty for this account is ' + leftQty, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            notWhiteListed();
        } else {
            var priceToPay = mintPrice * selectedAmt

            console.log("priceToPay", priceToPay, isPrivate[0])
            if (isPrivate[0] === true) {
                console.log("Private category")
                let checkDataBase = await fetchData()
                console.log(checkDataBase.includes(currentWallet?.toLowerCase()));
                if (checkDataBase.includes(currentWallet?.toLowerCase())) {
                    await mint({ tokenPrice: priceToPay, tokenQuantity: selectedAmt, isWhiteListed: true })
                } else {
                    notWhiteListed();
                }
            } else {
                await mint({ tokenPrice: priceToPay, tokenQuantity: selectedAmt })
            }
        }
        setFormFilled(true);
        setLoader(false)

        }
        
        // if(totalPrice.length >= 4){
        //     totalPrice.slice(0,5)
        // }       
    }

    event.removeAllListeners("disable-mint")
    event.on("disable-mint", () => {
        setFormFilled(false);
    });
    event.removeAllListeners("enable-mint")
    event.on("enable-mint", () => {
        setFormFilled(true);
    });

    event.removeAllListeners("transactionStart")
    event.on("transactionStart", () => {
        console.log("transactionStart")
        setLoader(true)
    });

    event.removeAllListeners("transactionEnded")
    event.on("transactionEnded", () => {
        console.log("transactionEnded")
        checkMinted();
        setLoader(false)
        // window.setTimeout(function(){window.location.reload()},5000)
    });

    // event.removeAllListeners("disconnected")
    // event.on("disconnected", () => {
    //     setFormFilled(false);
    // });

    // event.removeAllListeners("connected")
    // event.on("connected", () => {
    //     setFormFilled(true);
    //     console.log("connected");
    // });
    // event.removeAllListeners("notconnected")
    // event.on("notconnected", () => {
    //     setFormFilled(false);
    //     console.log("not conneced disable");
    // });
    // console.log(Math.ceil(mintedValue/totalValue*100)-0.5);

    return (
        <div>
            <ToastContainer />
            <Container className='custom_home_container'>
                <Row className='gy-4 custom_row'>

                    <Col xs={12} md={12} className='mb-4 mx-auto position-relative'>
                        {/* <ProgressBar variant="success" now={ Math.ceil(mintedValue/totalValue*100)+0.5 } label={`${mintedValue}`} className="rounded-0 height_6 fw-bold" /> */}
                        <div className="pieBar">
                            <div className="filler" style={{ "width": Math.ceil(mintedValue / totalValue * 100) + "%" }}>
                                <span>{mintedValue}</span>
                            </div>
                            <span className="end">{totalValue}</span>
                        </div>
                    </Col>
                    <Col xs={12} md={8} lg={6} className="mx-auto custom_col">
                        {/* <div className='bg_img'> */}
                        <img className='img-fluid mx-auto custom_img overflow-hidden' src={NftImage} alt='Nft' />
                        {/* </div> */}
                    </Col>
                    <Col xs={12} md={8} lg={6} className="mx-auto custom_col">
                        <div className='bg-color custom_col_content customHeight custom_padding p-1 text-light'>
                            <h2 className='text-center mb-4 fw-light'>Mint Your PieVerse<br />Genesis <span className='fw-bold'>Master Key</span><br /> Digital Asset</h2>
                            <ul className='nav flex-column next_ul'>
                                <li className='nav-item d-flex justify-content-between align-items-center bg-list text-light mb-2'>
                                    <div className='bg-list width-100 py-sm-2 px-sm-3 px-2 py-1 text-start fw-bold'>Minted</div>
                                    <span className='bg-list width-100 py-sm-1 px-sm-1 px-1 py-2 text-end custom_lineheight fw-light'>{mintedValue} / {totalValue}</span>
                                </li>
                                <li className='nav-item d-flex justify-content-between align-items-center bg-list text-light mb-2'>
                                    <div className='bg-list width-100 py-sm-2 px-sm-3 px-2 py-1 text-start fw-bold'>Price</div>
                                    <span className='bg-list width-100 py-sm-1 px-sm-1 px-1 py-2 text-end custom_lineheight fw-light'>{mintPrice} ETH</span>
                                </li>
                                <li className='nav-item d-flex justify-content-between bg-list text-light mb-3'>
                                    <div className='bg-list width-100 py-sm-2 px-sm-3 px-2 py-1 text-start fw-bold'>Quantity</div>
                                    <div className='d-flex'>
                                        <button className='border-0 bg-list customBtn border-color text-center text-light px-sm-3 px-1 '
                                            onClick={(e) => {
                                                if ((Math.floor(selectedAmt) - 1) < 1) { return; }
                                                setSelectedAmt(Math.floor(selectedAmt) - 1);
                                                console.log("selectedAmt", selectedAmt);
                                            }
                                            }
                                        > - </button>
                                        <input className='fw-bold border-0 bg-list border-color text-center text-light px-sm-3 px-1' type="number"
                                            name=""
                                            value={selectedAmt}
                                            onChange={(e) => {
                                                if (e.target.value <= maxQtyCategory) { setSelectedAmt(e.target.value) }
                                            }
                                            }
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }
                                            } min="1" max="5" required id="nftMintAmt" />
                                        <button className='border-0 bg-list customBtn border-color text-center text-light px-1 px-sm-3' onClick={(e) => {
                                            if ((Math.floor(selectedAmt) + 1) > maxQtyCategory) { return; }
                                            setSelectedAmt(Math.floor(selectedAmt) + 1);
                                            console.log("selectedAmt", selectedAmt);
                                        }
                                        }
                                        > + </button>
                                    </div>
                                    <div className='bg-list width-100 py-sm-1 px-sm-1 px-1 py-2 text-end fw-light custom_lineheight customCss' >{totalPrice.slice(0, 5)} ETH</div>
                                </li>
                            </ul>
                            <div className='text-center d-flex align-items-center justify-content-center flex-column'>
                                <Button type="submit"
                                    color={formFilled === true ? "#33ffff" : "gray"}
                                    buttonClass="text-dark text-uppercase mint_submitBtn rounded-pill py-sm-3 px-sm-5 px-1 py-2 border-0 fw-bold mb-3 cursor-pointer"
                                    onClick={mintFunction}
                                    disable={formFilled === false ? "disabled" : ""}
                                >Mint Now</Button>
                                <a href="https://global.transak.com/" target="_blank" className='buyEthLink' style={{ color: "#fff", fontSize: "0.75rem", marginBottom: "-15px" }} rel="noreferrer">Buy ETH</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {loader === true ?
                <div className='mint_loader'>
                    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                : ""}

        </div>
    )
}
