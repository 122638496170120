
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

import event from "./Event"
import abi from "./../../abis/pieverseABI.json";

const address = process.env.REACT_APP_CONTRACT_ADDRESS;

const ContractChainID = process.env.REACT_APP_CHAIN_ID_HEX;

let readProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
let readContract = new ethers.Contract(address, abi, readProvider);

const categoryId = process.env.REACT_APP_CATEGORY_ID;

const privateKey = process.env.REACT_APP_PRIVATE_KEY;

export const connectMetaMask = async () => {
  let accIs;
  if (window.ethereum) {
    let provider = window.ethereum;

    if (typeof provider !== 'undefined') {
      try {
        // check if the chain to connect to is installed
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: ContractChainID }], // chainId must be in hexadecimal numbers
        });
      } catch (error) {
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: ContractChainID,
                  rpcUrls: ['https://rinkeby.etherscan.io/'],
                  chainName: 'Matic(Polygon) Mumbai Testnet'
                }
              ]
            });
          } catch (addError) {
            console.error(addError)
            alert("Operation failed. Choose the Mumbai Testnet on your wallet")
            return
          }
        } else {
          console.error(error)
          alert("Operation failed. Choose the RinkeyBy Eth on your wallet")
          return
        }
      }
      await provider.request({ method: 'eth_requestAccounts' }).then(result => {
        accountChangeHandler(result[0])
        accIs = result[0]
        console.log("return acccout is----", result[0]);
        return result[0]
      }).catch(err => {
        console.log(err);
        sessionStorage.setItem("PieVerse_IsLoggedIn", 0);
        sessionStorage.setItem("PieVerse_walletAddress", "");
        // alert("Something went wrong 222")
        if (err.code === 4001) {
          alert("Rejected By user")
        }
      })
    }

    provider.on('accountsChanged', accountChangeHandler);
    provider.on('disconnect', async function () {
      console.log("disconnected called");
      window.location.reload(false);
    });
  } else {
    alert("Install Meta Mask")
  }
  return accIs
}
// export {event};
const accountChangeHandler = (newAccount) => {
  console.log("On change called", newAccount);
  sessionStorage.setItem("PieVerse_IsLoggedIn", 1);
  sessionStorage.setItem("PieVerse_walletAddress", newAccount);
  localStorage.setItem('WalletAccount', newAccount);
  event.emit('account-change', newAccount);
  return newAccount;
}

const whitelistnow = async (address) => {
  console.log("address : ", address);
  const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
  const walletWithProvider = new ethers.Wallet(privateKey, ethProvider);
  const nonce = await readContract.nonces(address);

  console.log("nonce on whitelist : ", nonce.toString());
  console.log(address, categoryId, nonce);
  const packedData = ethers.utils.solidityPack(
    ["address", "uint256", "uint256"],
    [address, categoryId, nonce]
  );
  console.log("packed data :");
  console.log("packed data :", packedData);
  const signature = await walletWithProvider.signMessage(
    ethers.utils.arrayify(packedData)
  );
  console.log("Signature is : ", signature);
  return signature;
}

export const categoryDetalils = async () => {
  let categoryData = await readContract.categories(categoryId);
  console.log("category deails : ", categoryData);
  let isPrivate = categoryData.isPrivate
  let priceIs = ethers.utils.formatEther(categoryData.price)
  return [isPrivate, priceIs]
}

export const contractMaxQty = async () => {
  const awaitMaxQty = await readContract.maxQty();
  const maxQty = awaitMaxQty.toString()
  return maxQty
}

export const qtyMinted = async () => {
  const awaitqtyMinted = await readContract.totalQtyMinted();
  const mintedQty = awaitqtyMinted.toString()
  return mintedQty
}

const userRejected = () => toast.error('Rejected by User', {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
});

const txSuccess = () => toast.success('Transaction Successfull', {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
});


const txError = async function (errMessage="There has been an error"){
  let lower = errMessage.toLowerCase();
  let errorMessage =  errMessage.charAt(0).toUpperCase() + lower.slice(1);
  toast.error(errorMessage, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
} 





export const mint = async ({ tokenPrice, tokenQuantity, isWhiteListed }) => {
  // let checkmetmask = await connectMetaMask();
  // console.log("checkmetmask", checkmetmask);
  let res = sessionStorage.getItem("PieVerse_walletAddress")
  let metaMaskaddr = sessionStorage.getItem("PieVerse_walletAddress")
  var contractSigner = await contractSignerFunction()
  if (isWhiteListed === true) {
    var whitelistSignature = await whitelistnow(metaMaskaddr);
    // let valueIs = BigInt(1000000000000000000*tokenPrice)
    console.log("price value is -", tokenPrice);
    console.log("from account -", metaMaskaddr);
    console.log("Token Quantity -", tokenQuantity);
    const mintOptions = { from: metaMaskaddr, gasLimit: 210000, value: ethers.utils.parseEther(tokenPrice.toString()) };
    
    try {
      let checkCallStatic = await contractSigner.callStatic.mintTokens(categoryId, tokenQuantity, whitelistSignature, mintOptions)
      console.log("checkCallStatic Private --", checkCallStatic)

      let tx = await contractSigner.mintTokens(categoryId, tokenQuantity, whitelistSignature, mintOptions)
      console.log("tx --", tx)
      transactionStart()
      let mintResult = await tx.wait();
      console.log("status is ----->", mintResult);
      try {
        if (mintResult) {
          txSuccess()
          transactionEnded()
          console.log(mintResult)
        }
      } catch (error) {
        console.error(`There is an error : ${error}`);
        transactionEnded()
        txError()
      }
    }catch (e) {
      console.log("error Code is-", e.code, e?.error?.code);
      if (e.code === 4001) {
        console.log("user rejected");
        userRejected()
      } else if(e?.error?.code === -32000) {
        transactionEnded()
        txError("insufficient funds")
      } else {
        console.log("error in Private sale ", e);
        console.log("error is-", e.reason);
        transactionEnded()
        txError(e.reason)
      }
    }
  } else {
    console.log("Not Private");
    if (res && res !== undefined) {
      // let valueIs = BigInt(1000000000000000000*tokenPrice)
      console.log("price value is -", ethers.utils.parseEther(tokenPrice.toString()));
      const mintOptions = { from: res.toLowerCase(), gasLimit: 210000, value: ethers.utils.parseEther(tokenPrice.toString()).toString() };
      console.log("mintOptions", mintOptions);
      try {
        console.log("contractSigner", contractSigner)

        let checkCallStatic = await contractSigner.callStatic.mintTokens(categoryId, tokenQuantity, '0x', mintOptions);
        console.log("checkCallStatic Public --", checkCallStatic)


        let tx = await contractSigner.mintTokens(categoryId, tokenQuantity, '0x', mintOptions);

        transactionStart()
        console.log("tx --", tx)
        let mintResult = await tx.wait();
        console.log("status is ----->", mintResult);
        try {
          if (mintResult) {
            transactionEnded()
            txSuccess()
            console.log(mintResult)
          }
        } catch (error) {
          console.error(`There is an error : ${error}`);
          transactionEnded()
          txError()
        }
      }
      catch (e) {
        console.log("error is-", e);
        console.log("error Code is-", e.code);
        if (e.code === 4001) {
          console.log("user rejected");
          userRejected()
        } else if(e?.error?.code === -32000) {
          transactionEnded()
          txError("insufficient funds")
        } else {
          console.log("error in Public sale -", e);
          console.log("error is-", e.reason);
          transactionEnded()
          txError(e.reason)
        }
      }
    }
  }
}

const contractSignerFunction = async () => {
  if (window.ethereum) {
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let contract = new ethers.Contract(address, abi, provider);
    const contractSigner = contract.connect(signer);
    return contractSigner;
  } else {
    alert("Install Meta Mask")
  }
}

const transactionStart = () => {
  event.emit('transactionStart');
}

const transactionEnded = () => {
  event.emit('transactionEnded');
}
export const maxQtyPerUser = async () => {
  let response = await readContract.categories(categoryId);
  console.log("Response is Category : ", parseInt(response.maxPerAddress));
  return parseInt(response.maxPerAddress);
}
export const tokensMintedPerCategoryPerAddress = async () => {
  let selectedWallet = sessionStorage.getItem("PieVerse_walletAddress")
  let response = await readContract.tokensMintedPerCategoryPerAddress(selectedWallet, categoryId);
  console.log("Response is : ", response);
  return parseInt(response);
} 