import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assist/SVG/PieVerse-logo-verticle.svg';
import { Button } from '../Button/Button';
import { connectMetaMask } from '../hooks/connectMetaMask';
import Popup from '../PopUp/popUp';
// import ReCAPTCHA from "react-google-recaptcha";
import event from "./../hooks/Event"
import { useCookies } from "react-cookie";
import sanityClient from "@sanity/client";
import Accountmodal from '../AccountModal/Accountmodal';
// import handler from '../api/userData';

const userClient = sanityClient({
  "projectId": "ra1jx1td",
  "dataset": "production",
  token: "skGFXjuRKJXhqW0hSsqmmIHJjnw558AabdIDcCzUhNdjsfQegZu4sKvj958teboCOdnUsmyrtRvXj1GsQXa3HMzEv9dkHlFdT1Umd4k2bywAMg9phjHWSF8Sa9O1CHdf0pnDdbqYS2IOA8L8lnUQpMMFGoZu143Gswfqou40Fe6jpq8TdXWd",
  useCdn: false
});

export const Header = () => {

  const [isConnected, setIsConnected] = useState("Connect Wallet")
  const [acc, setAccount] = useState(null)
  const [isPopUp, setIsPopUp] = useState(false)
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [membderShipNo, setMembderShipNo] = useState("")
  // const [isVerified,setIsVerified] = useState(false)
  const [accountExist, setAccountExist] = useState(false)
  const [validEmail, setValidEmail] = useState(null)
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [currentWallet, setCurrentWallet] = useState()

  useEffect(() => {
    setCurrentWallet(cookies.PieVerse_walletAddress)
  }, [cookies.PieVerse_walletAddress])

  // event.removeAllListeners('account-change');
  // event.on('account-change', (newAccont) => {
  //   setAccount(newAccont);
  //   console.log("wallet", newAccont);
  // })
  // console.log(evt)

  useEffect(() => {
    let checkConnected = sessionStorage.getItem("PieVerse_IsLoggedIn");
    console.log("account is----->", checkConnected)
    // if (checkConnected === null || checkConnected === 0) {
    //   event.emit("disable-mint");
    //   setIsConnected("Connect Wallet")
    //   // disconnectAcc();
    //   console.log("checkConnected is----->", checkConnected)
    // }
    // if (acc === null && checkConnected === null) {
    //   setIsConnected("Connect Wallet")
    // }

    checkLogin();

  }, [currentWallet])

  async function checkLogin() {
    console.log("Check Login Called");
    let isLoggin = sessionStorage.getItem("PieVerse_IsLoggedIn");
    let walletAddress = currentWallet;
    console.log("isLoggin", isLoggin)
    if (isLoggin !== 0 && isLoggin !== undefined && isLoggin !== null && walletAddress !== "" && walletAddress !== undefined) {

      console.log("inside Check Login");
      setAccount(walletAddress);
      setIsConnected("Connected")
      console.log("walletAddress", walletAddress);
      try {
        let checkAddress = await userClient.fetch(`*[_type == 'userData'].wallet`);
        console.log("checkAddress ", checkAddress, walletAddress)
        let secondCheck = checkAddress.includes(walletAddress.toLowerCase());
        console.log("secondCheck", secondCheck)
        if (secondCheck === false) {
          console.log("Popup Show");
          setIsPopUp(true);
          event.emit("disable-mint");
        } else {
          console.log("Popup Hide");
          setIsPopUp(false);
          event.emit("enable-mint");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  event.removeAllListeners("checkLogin");
  event.on("checkLogin", checkLogin)


  // useEffect ( () => {
  //   console.log("account is----->", acc)
  //   if(acc === null){
  //     event.emit("notconnected");
  //     setIsConnected("Connect Wallet")
  //   }else{
  //     setIsConnected("Connect")
  //   }
  // },[acc])



  useEffect(() => {
    async function fetchUserData() {
      let userdata = await userClient.fetch(`*[_type == 'userData'].wallet`);
      console.log("User Data is", userdata);
      setAccountExist(userdata)
      return userdata
    }
    fetchUserData()
  }, [])

  // const connectAcc = async () => {
  //   // let res = await connectMetaMask();
  //   let res = event.emit("connectWallet")
  //   console.log("response is--->", res);
  //   if (res && res !== undefined) {
  //     event.emit("enable-mint");
  //     setAccount(res);
  //     sessionStorage.setItem("PieVerse_IsLoggedIn", 1);
  //     sessionStorage.setItem("PieVerse_walletAddress", res);
  //     setIsConnected("Connected")
  //   } else {
  //     setIsConnected("Connect Wallet")
  //     closePopUp()
  //   }
  //   return res
  // }

  // const disconnectAcc = ()=> {
  //   event.emit("disable-mint");
  //   sessionStorage.setItem("PieVerse_IsLoggedIn", 0);
  //   sessionStorage.setItem("PieVerse_walletAddress", "");
  //   // sessionStorage.clear();
  //   setIsConnected("Connect Wallet")
  // }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const closePopUp = () => {
    setIsPopUp(false);
  }

  // const handleCaptcha = (value) => {
  //   console.log("captcha :", value );
  //   setIsVerified(true)
  // }

  // const postData = async() => {

  //     await fetch(handler({
  //       method: "POST",
  //       body: JSON.stringify({
  //         firstName: firstName,
  //         lastName: lastName,
  //         email: email,
  //         wallet: acc,
  //         memberId: membderShipNo,
  //       }),
  //     })
  //     )

  //   console.log( fetch);
  // }

  const testPost = () => {
    if (!isValidEmail(email)) {
      setValidEmail('Valid Email Required');
    } else {
      const newUserData = {
        _type: 'userData',
        firstName: firstName,
        lastName: lastName,
        email: email.toLowerCase(),
        wallet: acc?.toLowerCase(),
        memberId: membderShipNo ? parseInt(membderShipNo) : 0,
      };
      userClient.create(newUserData).then(() => {
        console.log("created");
        setEmail("");
        setFirstName("");
        setLastName("");
        setMembderShipNo("");
        setValidEmail(null);
        setIsPopUp(false);
        window.location.reload();
      }).catch((err) => {
        console.log("Error", err);
        setIsPopUp(true)

      })

    }
  }

  return (
    <Navbar variant="dark" className='py-5 nav_ss'>
      <Container className='custom_header_container flex-wrap'>
        <Navbar.Brand href="https://www.pieverse.au/">
          <img className='img-fluid header_logo' src={Logo} alt="Minting Nest" />
          <h2 className='text-start fw-light nav_brand_text'>Minting Nest </h2>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse >
          <Navbar.Text>
            <Accountmodal />
            {/* <Button 
              type="submit" 
              color="#fff" 
              onClick={ connectAcc } 
              buttonClass="text-dark text-uppercase rounded-pill py-3 px-4 fw-bold"
            
              >
                {isConnected}
              </Button> */}
            {isPopUp === true ?
              <Popup handleClose={closePopUp}>
                <h3 className='text-center mb-4 fw-bold'>Fill Form to Proceed</h3>
                <div className='nav flex-column'>
                  {validEmail && <label className='email_validation' >{validEmail}</label>}
                  <input
                    className='nav-item align-items-center bg-list text-light py-sm-2 px-sm-3 mb-2 px-2 py-1 border-0 '
                    type="email"
                    placeholder='Email (required)'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    id="PopupFormEmail"
                  />
                  <input
                    className='nav-item align-items-center bg-list text-light py-sm-2 px-sm-3 mb-2 px-2 py-1 border-0 '
                    type="text"
                    placeholder='First Name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    id="PopupFormFirstName"
                  />
                  <input
                    className='nav-item align-items-center bg-list text-light py-sm-2 px-sm-3 mb-2 px-2 py-1 border-0 '
                    type="text"
                    placeholder='Last Name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    id="PopupFormLastName"
                  />


                  <input
                    className='nav-item align-items-center bg-list text-light py-sm-2 px-sm-3 mb-2 px-2 py-1 border-0 '
                    type="number"
                    placeholder='Existing Membership Number'
                    value={membderShipNo}
                    onChange={(e) => setMembderShipNo(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }
                    } min="0"
                    id="PopupFormMembershipNo"
                  />
                  <input
                    className='nav-item align-items-center bg-list text-light py-sm-2 px-sm-3 mb-2 px-2 py-1 border-0 '
                    type="text"
                    placeholder='Wallet Address'
                    value={acc !== null ? acc.slice(0, 9) + `...` + acc.slice(-9) : ""}
                    required
                    disabled
                    id="PopupFormEmail"
                  />
                </div>

                <div className='text-center mt-2'>
                  {/* <ReCAPTCHA
                    id='RecaptchDiv'
                    sitekey="6LcCmn4hAAAAAFbBwqCknVLgz9HDExJ4zqw7PyRb"
                    onChange={handleCaptcha}
                  /> */}
                  <Button type="submit" color="#33ffff" buttonClass="text-dark text-uppercase rounded-pill py-sm-3 px-sm-5 px-1 py-2 border-0 fw-bold cursor-pointer"
                    onClick={testPost}
                  // disable={isVerified === true ? "" : "disabled"} 
                  >submit</Button>
                </div>
              </Popup>
              : ""}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
