import React from 'react';
import { WhiteList } from '../component/WhiteList/WhiteList'

const WhiteListPage = () => {
  return (
    <div className='content'>
      <WhiteList />
    </div>
  )
}

export default WhiteListPage
