import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Papa from "papaparse";
import { Button } from '../Button/Button';
import { ToastContainer, toast } from 'react-toastify';
import sanityClient from "@sanity/client";


const userClient = sanityClient({
  "projectId": process.env.REACT_APP_PROJECT_ID,
  "dataset": process.env.REACT_APP_DATASET,
  "token": process.env.REACT_APP_TOKEN,
  useCdn: false
});

const allowedExtensions = ["csv"];
export const WhiteList = () => {
  const [selectedFile, setSelectedFile] = useState("");
  const [userwalletAddress, setUserwalletAddress] = useState("")
  const invalidFileError = () => toast.error('Invalid File type. Please upload CSV file only', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

  const importCompleted = () => toast.success('Import Completed.', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

  const successMessage = () => toast.success('Address added to whitelist.', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        invalidFileError()
        return;
      }
      setSelectedFile(inputFile);
    }
  };
  const addWhiteListAddress = () => {
    if (userwalletAddress !== "") {
      const newWhitelist = {
        _type: 'whitelist',
        account: userwalletAddress?.toLowerCase(),
      };
      userClient.create(newWhitelist).then(() => {
        console.log("Address Added - ", userwalletAddress);
      }).catch((err) => {
        console.log("Error in adding Address", userwalletAddress, err);
      })
      setUserwalletAddress("");
      successMessage();
    }
  }

  const uploadData = async () => {
    if (!selectedFile) {
      invalidFileError();
    } else {
      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: false });
        const parsedData = csv?.data;
        for await (const csvData of parsedData) {
          let walletAddress = csvData[0];
          if (walletAddress !== "0x0000000000000000000000000000000000000000" && walletAddress !== "" && walletAddress !== undefined) {
            const newWhitelist = {
              _type: 'whitelist',
              account: walletAddress?.toLowerCase(),
            };
            userClient.create(newWhitelist).then(() => {
              console.log("Address Added - ", walletAddress);
            }).catch((err) => {
              console.log("Error in adding Address", walletAddress, err);
            })
          }
        }
        importCompleted();
      };
      reader.readAsText(selectedFile);
    }
  };

  const deleteAllData = async () => {
    userClient.delete({ query: `*[_type == "whitelist"]` });
  }

  return (
    <div>
      <ToastContainer />
      <Container className='custom_home_container'>
        {/* <Row className='gy-4 custom_row'>
          <Button type="submit" color="#ff0000" buttonClass="text-dark text-uppercase  mb-4 rounded-pill py-sm-3 px-sm-5 px-1 py-2 border-0 fw-bold"
            onClick={deleteAllData} >Delete All Records</Button>
        </Row> */}
        <Row className='gy-4 custom_row'>
          <h3 className='text-left fw-bold color-white'>Add walletAddress</h3>
          <div className='nav flex-column'>
            <input
              className='nav-item align-items-center bg-list text-light py-sm-2 px-sm-3 mb-2 px-2 py-1 border-0 '
              type="text"
              value={userwalletAddress}
              onChange={(e) => setUserwalletAddress(e.target.value)}
            />

          </div>

          <div className='text-center mt-2'>
            <Button type="submit" color="#33ffff" buttonClass="text-dark text-uppercase rounded-pill mb-4 py-sm-3 px-sm-5 px-1 py-2 border-0 fw-bold"
              onClick={addWhiteListAddress}
            >Submit</Button>
          </div>
        </Row>
        <Row className='gy-4 custom_row'>
          <h3 className='text-left fw-bold color-white'>Add CSV</h3>
          <div className='nav flex-column'>
            <input
              className='nav-item align-items-center bg-list text-light py-sm-2 px-sm-3 mb-2 px-2 py-1 border-0 '
              type="file"
              onChange={handleFileChange}
              id="PopupFormFirstName"
            />

          </div>

          <div className='text-center mt-2'>
            <Button type="submit" color="#33ffff" buttonClass="text-dark text-uppercase  mb-4 rounded-pill py-sm-3 px-sm-5 px-1 py-2 border-0 fw-bold"
              onClick={uploadData}
            >Upload</Button>
          </div>
        </Row>
      </Container>
    </div>
  )
}

