import React from 'react';
import { Home } from '../component/Home/Home'

const HomePage = () => {
  return (
    <div className='content'>
      <Home />
    </div>
  )
}

export default HomePage
