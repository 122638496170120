import React from "react";
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 
const Popup = props => {
  return (
    <div className="popUp_div">
      <Container className='custom_popup_container'>
        <Row className='gy-4 custom_row'>
            <Col xs={10} md={8} lg={6} className="mx-auto custom_col ">
                <div className='bg-color  px-sm-5 py-sm-3 p-1 text-light popUp_col'>
                  {/* <span className="close-icon" onClick={props.handleClose}>x</span> */}
                    {props.children}
                </div>
            </Col>
        </Row>
      </Container>
    </div>
    
  );
};
 
export default Popup;